<!--
  ~ DIPNN DT Lyon NEO SY 06/10/2021 14:58
  ~ index.component.html - infoevacuation - f35380
  -->

<div class="container" style="margin: 0 auto; text-align: center; padding: 0">
  <div>
    <img src="/assets/img/logo_EDF_RVB.svg" alt="logo EDF" width="150rem" height="77" />
    <h1>InfoEvacuation</h1>

    <a href="/api/oidc/login" mat-flat-button color="primary">Connexion</a>
  </div>
</div>
