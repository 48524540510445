/*
 * DIPNN DT Lyon NEO SY 08/07/2021 17:08
 * app.component.ts - infoevacuation - f35380
 */

import { Component } from '@angular/core';
import { AuthentificationService } from './shared/services/authentification/authentification.service';
import { ActivatedRoute } from '@angular/router';
import { ErrorsHandlerService } from './shared/services/errors_handler/errors-handler.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  constructor(
    public Auth: AuthentificationService,
    private activatedRoute: ActivatedRoute,
    private error: ErrorsHandlerService
  ) {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      if (params.get('error')) {
        this.error.handle_error(params.get('error') || '');
        this.error.display = false;
      }
    });
  }
}
