/*
 * EDF DIPNN DT NEO SY 20/12/2021 14:39
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { RouterModule } from '@angular/router';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PhoneNumber } from '../pipes/phonenumber';
import { FooterComponent } from './footer/footer.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [HeaderComponent, PhoneNumber, FooterComponent],
  exports: [
    HeaderComponent,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    PhoneNumber,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    RouterModule,
    ToastrModule.forRoot({
      maxOpened: 2,
      autoDismiss: true,
      closeButton: true,
    }),
  ],
})
export class ThemeModule {}
