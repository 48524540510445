/*
 * EDF DIPNN DT NEO SY 06/12/2021 21:38
 */

import { Component } from '@angular/core';
import { AuthentificationService } from '../shared/services/authentification/authentification.service';
import { Utilisateur } from '../shared/interfaces/utilisateur';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent {
  constructor(private Authentification: AuthentificationService) {
    this.Authentification.getUserObservable().subscribe(
      (user: Utilisateur | undefined) => {
        if (user?.is_fully_authenticated) {
          this.Authentification.redirectTo(['visualisation', 'batiments']);
        }
      }
    );
  }
}
