/*
 * EDF DIPNN DT NEO SY 21/12/2021 15:03
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from './theme/theme.module';
import { AuthentificationService } from './services/authentification/authentification.service';
import { InfoEvacuationService } from './services/infoevacuation/infoevacuation.service';
import { WebsocketService } from './services/websocket/websocket.service';
import { ErrorsHandlerService } from './services/errors_handler/errors-handler.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandlerInterceptor } from './interceptor/errorhandler.interceptor';
import {FhmerrorInterceptor} from "./interceptor/fhmerror.interceptor";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [ThemeModule],
  providers: [
    AuthentificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FhmerrorInterceptor,
      multi: true,
    },
    WebsocketService,
    ErrorsHandlerService,
    InfoEvacuationService,
  ],
})
export class SharedModule {}
