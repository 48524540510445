/*
 * DIPNN DT Lyon NEO SY 06/10/2021 14:58
 * app-routing.module.ts - infoevacuation - f35380
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { FakeLoginComponent } from './fake-login/fake-login.component';
import { RgpdComponent } from './rgpd/rgpd.component';

const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: 'rgpd', component: RgpdComponent },
  { path: 'login', component: FakeLoginComponent },
  {
    path: 'visualisation',
    loadChildren: () =>
      import('./visualisation/visualisation.module').then(
        (m) => m.VisualisationModule
      ),
  },
  {
    path: 'signalement',
    loadChildren: () =>
      import('./reporting/reporting.module').then((m) => m.ReportingModule),
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
