/*
 * EDF DIPNN DT NEO SY 21/12/2021 15:03
 */

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {ErrorsHandlerService} from "../services/errors_handler/errors-handler.service";

@Injectable()
export class FhmerrorInterceptor implements HttpInterceptor{

  constructor(private errorhandler: ErrorsHandlerService){}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
         return next.handle(req).pipe(map((event : HttpEvent<any>) =>{
           if (event instanceof HttpResponse && event.headers.get("content-type") === "text/html" && event.body.hasOwnProperty("Support ID")){
             console.error("SupportID", event.body["Support ID"])
             this.errorhandler.handle_error("errors.fhm", true, "Support ID: "+event.body["Support ID"], {"tapToDismiss":false})
           }
           return event;
         }))
    };


}
