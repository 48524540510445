/*
 * EDF DIPNN DT NEO SY 21/10/2021 15:45
 */

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Batiment } from '../../interfaces/batiment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Zone } from '../../interfaces/zone';
import { Commentaires } from '../../interfaces/commentaires';
import { UntypedFormGroup } from '@angular/forms';
import { Utilisateur } from '../../interfaces/utilisateur';
import { isPlatformBrowser } from '@angular/common';
import { Version } from '../../interfaces/version';
import { Status } from '../../interfaces/status';

@Injectable({
  providedIn: 'root',
})
/**
 * Service de gestion de l'API métier
 */
export class InfoEvacuationService {
  /**
   * Liste des bâtiments
   * @type Batiment[]
   */
  batiments?: Batiment[];

  /**
   * Savoir le type de platforme
   */
  isBrowser: boolean = isPlatformBrowser(this.platformId);

  /**
   * Savoir si plein écran activé
   */
  fullscreen: boolean = false;

  /**
   * Savoir si l'application est dégradée
   */
  app_degradee: boolean = false;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (this.isBrowser) {
      document.addEventListener('fullscreenchange', (event) => {
        this.fullscreen = !this.fullscreen;
      });
    }
  }

  /**
   * Récupère la liste des sites (uniquement avec le fake auth activé)
   * @return Observable
   */
  get_sites(): Observable<any> {
    return this.http.get('/api/sites');
  }

  /**
   * Toggle de plein écran
   */
  toggleFullscreen() {
    if (this.isBrowser) {
      if (this.fullscreen) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
    }
  }

  /**
   * Récupère une zone
   */
  get_zone(zone: string, zone_code: string): Observable<Zone> {
    return this.http.get<Zone>('/api/zones/' + zone + '/' + zone_code);
  }

  /**
   * Récupère un utilisateur
   */
  get_utilisateur(id: number): Observable<Utilisateur> {
    return this.http.get<Utilisateur>('/api/personnes/' + id);
  }

  /**
   * Récupère la liste des commentaires standards
   */
  get_commentaires(): Observable<Commentaires[]> {
    return this.http.get<Commentaires[]>('/api/commentaires');
  }

  /**
   * Envoie le rapport
   */
  post_reporting(formulaire: UntypedFormGroup): Observable<any> {
    return this.http.post('/api/action', formulaire.value);
  }

  /**
   * Récupère la liste des bâtiments et les ajoutes à {@link: batiments}
   * @return void
   */
  get_batiments(): Observable<Batiment[]> {
    return this.http.get<Batiment[]>('/api/batiments');
  }

  /**
   * Récupère un batiment
   */
  get_batiment(id: number, plan_au_sol = false): Observable<Batiment> {
    return this.http.get<Batiment>(
      '/api/batiments/' + id + (plan_au_sol ? '?plan_au_sol=true' : '')
    );
  }

  /**
   * Récupère les zones d'un bâtiment
   */
  get_batiment_zones(id: number): Observable<Zone[]> {
    return this.http.get<Zone[]>('/api/batiments/' + id + '/zones');
  }

  /**
   * Récupère le statut de l'application
   */
  get_version(): Observable<Version> {
    return this.http.get<Version>('/api/version');
  }

  /**
   * Récuèpère la santé limité de l'application
   */
  get_status(): Observable<Status> {
    return this.http.get<Status>('/api/status');
  }
}
