<!--
  ~ DIPNN DT Lyon NEO SY 06/10/2021 14:58
  ~ fake-login.component.html - infoevacuation - f35380
  -->

<ng-template [ngIf]="sites">
  <div class="mt-4" style="margin: 0 auto; width: 15rem">
    <div class="text-justify">
      <h1>Connexion</h1>
      <h3>Authentification de développement</h3>
    </div>
    <div>
      <form [formGroup]="auth_form" (ngSubmit)="authentification()">
        <mat-form-field appearance="outline">
          <mat-label>Votre NNI</mat-label>
          <input matInput required maxlength="10" minlength="6" formControlName="nni"/>
        </mat-form-field>
        <br />
        <mat-form-field appearance="outline">
          <mat-label>Nom</mat-label>
          <input matInput type="text" formControlName="nom" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Prénom</mat-label>
          <input matInput type="text" formControlName="prenom" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>E-mail</mat-label>
          <input matInput type="text" formControlName="email" />
        </mat-form-field>
        <br />
        <div>
          <label for="priv_select">Niveau de privilège</label>
          <select id="priv_select" formControlName="statut" required>
            <option value="ei">Equipier incendie</option>
            <option value="responsable">Responsable</option>
            <option value="staff">Staff</option>
            <option value="superuser">Administrateur application</option>
          </select>
        </div>
        <br />
        <div>
          <label for="site_select">Site</label>
          <select id="site_select" formControlName="site" required style="display: block">
            <option *ngFor="let site of sites" [value]="site.id">
              {{ site.nom }}
            </option>
          </select>
        </div>

        <button style="margin-top: 1rem" mat-flat-button color="primary" type="submit">
          Connexion
        </button>
      </form>
    </div>
  </div>
</ng-template>
