/*
 * EDF DIPNN DT NEO SY 20/12/2021 14:39
 */

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AuthentificationService } from '../authentification/authentification.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Websocket } from '../../interfaces/websocket';
import { isPlatformBrowser } from '@angular/common';
import { ErrorsHandlerService } from '../errors_handler/errors-handler.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private isBrowser: boolean = isPlatformBrowser(this.platformId);

  private prev_status : boolean = false;

  private connexion_statut: BehaviorSubject<boolean>;
  private message: BehaviorSubject<Websocket | undefined>;
  private readonly subject: WebSocketSubject<unknown> | undefined;

  constructor(
    private Authentification: AuthentificationService,
    private error_handler: ErrorsHandlerService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (this.isBrowser) {
      this.subject = webSocket({
        url:
          (environment.production ? 'wss://' : 'ws://') +
          location.host +
          '/api/websocket',
        openObserver: {
          next: () => {
            this.connexion_statut.next(true);
          },
        },
        closeObserver: {
          next: () => {
            this.connexion_statut.next(false);
          },
        },
      });
      let i = 0;

      this.Authentification.getUserObservable().subscribe((user) => {
        //console.log(user, this.subject.isStopped, i)
        // Si l'utilisateur est connecté
        if (user && user.is_fully_authenticated) {
          this.connect_ws();
          i += 1;
        } else if (this.subject && !this.subject.isStopped && i >= 1) {
          this.subject.complete();
        }
      });
    }

    this.connexion_statut = new BehaviorSubject<boolean>(false);
    this.message = new BehaviorSubject<Websocket | undefined>(undefined);
    this.showError();
  }
  connect_ws() {
    if (this.subject) {
      this.subject.subscribe(
        (message: unknown) => this.setMessage(message),
        (error) => {
          if (
            this.Authentification.getUser() ||
            !this.Authentification.requested
          ) {
            setTimeout(() => {
              this.connect_ws();
            }, 1000);
          }
        }
      );
    }
  }

  getWebSocketStatut(): Observable<boolean> {
    return this.connexion_statut.asObservable();
  }

  getWebSocketMessage(): Observable<Websocket | undefined> {
    return this.message.asObservable();
  }

  private setMessage(message: unknown): void {
    if (typeof message === 'object' && this.subject) {
      let formated_message = <Websocket>message;
      let config = {
        tapToDismiss: formated_message.action != 'errors.health',
        closeButton: formated_message.action != 'errors.health',
        timeOut: formated_message.action == 'errors.health' ? 0 : 5000,
        extendedTimeOut: formated_message.action == 'errors.health' ? 0 : 5000,
      };

      this.message.next(formated_message);
      if (formated_message.message) {
        switch (formated_message.severity) {
          case 'err':
            this.error_handler.toastr.error(
              formated_message.message,
              'InfoEvacuation',
              config
            );
            break;
          case 'success':
            this.error_handler.toastr.success(
              formated_message.message,
              'InfoEvacuation',
              config
            );
            break;
          case 'warn':
            this.error_handler.toastr.warning(
              formated_message.message,
              'InfoEvacuation',
              config
            );
            break;
          default:
            this.error_handler.toastr.info(
              formated_message.message,
              'InfoEvacuation',
              config
            );
        }
      }
      this.subject.next({ type: 'ACK' });
    }
  }

  showError() {
    this.getWebSocketStatut().subscribe((statut) => {
      if (statut && !this.prev_status) {
        this.error_handler.toastr.success('Connexion établie', 'WebSocket');
        this.setMessage({ type: 'map.reload' }); // Recharger la carte une fois la connexion revenue
        this.prev_status = true;
      } else if (!statut && this.prev_status && this.Authentification.getUser()) {
        this.error_handler.toastr.error(
          'Reconnexion en cours...',
          'WebSocket',
          { timeOut: 0, extendedTimeOut: 0 }
        );
        this.prev_status = false;
      }
    });
  }
}
