/*
 * DIPNN DT Lyon NEO SY 08/07/2021 17:08
 * errorhandler.interceptor.ts - infoevacuation - f35380
 */

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InfoEvacuationService } from '../services/infoevacuation/infoevacuation.service';
import { ErrorsHandlerService } from '../services/errors_handler/errors-handler.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private errorhandler: ErrorsHandlerService,
    private infoEvacuation: InfoEvacuationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.infoEvacuation.isBrowser) {
          this.errorhandler.handle_error(
            error.error instanceof Object
              ? error.error['error']
              : 'errors.unknown'
          );
          this.errorhandler.display = true;
        }
        return throwError(error);
      })
    );
  }
}
