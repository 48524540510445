/*
 * EDF DIPNN DT NEO SY 07/03/2022 10:58
 */

import { Injectable } from '@angular/core';
import { Utilisateur } from '../../interfaces/utilisateur';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationService {
  private utilisateur: BehaviorSubject<Utilisateur | undefined>;
  locked_out: boolean = false;
  loading: boolean = false;
  requested: boolean = false; // Si on a fait la requête pour avoir l'utilisateur

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.utilisateur = new BehaviorSubject<Utilisateur | undefined>(undefined);
  }

  /**
   * Récupération de l'utilisateur (Observable)
   */
  getUserObservable(): Observable<Utilisateur | undefined> {
    return this.utilisateur.asObservable();
  }

  /**
   * Change l'utilisateur et notifie les observateurs
   * @param utilisateur
   */
  setUser(utilisateur: Utilisateur | undefined): void {
    this.utilisateur.next(utilisateur);
  }

  /**
   * Redirige les utilisateurs sur la page spécifiée
   * @param route
   * @param extra
   */
  redirectTo(route: any[], extra?: NavigationExtras) {
    this.router.navigate(route, extra);
  }

  /**
   * Récupération de l'utilisateur auquel appartient le cookie
   */
  setCurrentUser() {
    this.loading = true;
    this.current_user().subscribe(
      (reply: Utilisateur | undefined) => {
        this.requested = true;
        this.loading = false;
        this.setUser(reply);
      },
      (error) => {
        this.requested = true;
        this.loading = false;
        this.setUser(undefined);
      }
    );
  }

  /**
   * Récupérer le numeroe de téléphone de l'utilisateur
   */
  getUserPhone(): string | undefined {
    return this.utilisateur.getValue()?.telephone_temp || this.utilisateur.getValue()?.telephone || this.utilisateur.getValue()?.telephone_2
  }


  /**
   * Récupération de la valeur de la variable utilisateur à l'instant T
   */
  getUser(): Utilisateur | undefined {
    return this.utilisateur.getValue();
  }

  /**
   * Requête vers l'endpoint de fausse authentification
   * @param formulaire
   */
  fake_authentification(formulaire: UntypedFormGroup): Observable<Utilisateur> {
    return this.http.post<Utilisateur>('/api/fake_auth', formulaire.value);
  }

  /**
   * Requête vers l'endpoint d'identification des équipiers incendie
   * @param formulaire
   */
  identification(formulaire: UntypedFormGroup): Observable<Utilisateur> {
    return this.http.post<Utilisateur>('/api/ei_login', formulaire.value);
  }

  /**
   * Requête de récupération de l'utilisateur courant
   * @private
   */
  private current_user(): Observable<Utilisateur> {
    return this.http.get<Utilisateur>('/api/current_user');
  }

  /**
   * Déconnexion de l'utilisateur courant
   */
  logout(display = true): void {
    this.http.post('/api/logout', {}).subscribe(
      (reply) => {
        this.setUser(undefined);
        if (display) this.toastr.success('Vous êtes déconnecté');
      },
      (error) => {
        this.setUser(undefined);
        if (display) this.toastr.success('Vous êtes déconnecté');
      }
    );
  }
}
