/*
 * EDF DIPNN DT NEO SY 21/12/2021 15:03
 */

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthentificationService } from '../authentification/authentification.service';
import { NavigationStart, Router } from '@angular/router';

interface ErrorDescription {
  message: string;
  severity: 'warn' | 'info' | 'err';
  key?: string;
}

interface ErrorsList {
  errors: {
    [composant: string]:
      | {
          [erreur: string]: ErrorDescription;
        }
      | ErrorDescription;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ErrorsHandlerService {
  display: boolean = true;
  lock: boolean = true;

  /**
   * Gestion des exceptions à la sécurité
   * @param toastr
   * @param Authentification
   * @param router
   */
  constructor(
    public toastr: ToastrService,
    private Authentification: AuthentificationService,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (
          event.url == '/' ||
          event.url.includes('/login') ||
          event.url.includes('/signalement/')
        ) {
          this.display = false;
          this.lock = false;
        } else {
          this.display = true;
          this.lock = true;
        }
      }
    });
  }

  /**
   * Dictionnaire des erreurs
   * @private
   */
  private errors: ErrorsList = {
    errors: {
      site: {
        unknown: {
          message: 'Site introuvable.',
          severity: 'warn',
        },
        bad: {
          message: "La ressource demandée n'est pas liée à votre site.",
          severity: 'err',
        },
      },
      zone: {
        unknown: {
          message: 'Zone introuvable',
          severity: 'warn',
        },
        invalid_position: {
          message: 'Une zone est déjà à cet étage et à cette position',
          severity: 'warn',
        },
      },
      evacuation: {
        no_site: {
          message: 'Site non existant',
          severity: 'warn',
        },
        ongoing: {
          message: 'Evacuation déjà en cours',
          severity: 'err',
        },
        unknown: {
          message: 'Evacuation introuvable',
          severity: 'warn',
        },
      },
      commentaire_standard: {
        unknown: {
          message: 'Commentaire standard introuvable',
          severity: 'err',
        },
      },
      action: {
        no_reason: {
          message: 'Merci de spécifier la raison du non-ok',
          severity: 'warn',
        },
        ok_with_com: {
          message:
            'Vous ne pouvez pas ajouter de commentaire si la situation est OK',
          severity: 'warn',
        },
        bad_site: {
          message:
            "Vous ne pouvez pas signaler le statut d'une zone qui n'est pas dans votre site",
          severity: 'warn',
        },
        bad_request: {
          message: 'Requête invalide, merci de réessayer',
          severity: 'warn',
        },
      },
      batiment: {
        unknown: {
          message: 'Bâtiment introuvable',
          severity: 'warn',
        },
        no_zones: {
          message: 'Aucune zone dans le bâtiment',
          severity: 'warn',
        },
      },
      personne: {
        unknown: {
          message: 'Utilisateur introuvable',
          severity: 'warn',
        },
        disabled: {
          message: 'Compte désactivé',
          severity: 'err',
        },
        ldap_no_email: {
          message:
            "Pas d'email pour l'utilisateur dans l'annuaire d'entreprise",
          severity: 'err',
        },
        missing_field: {
          message:
            'Il manque un champs obligatoire (nom, prenom, nni, email si responsable)',
          severity: 'warn',
        },
        duplicated: {
          message: 'Personne déjà présente dans la base',
          severity: 'err',
        },
      },
      fake_auth: {
        bad_request: {
          message: 'Erreur dans le formulaire',
          severity: 'warn',
        },
        ei_auth: {
          message: "Vous ne pouvez pas vous connecter en tant qu'équipier",
          severity: 'info',
        },
        parameter: {
          message:
            'Il manque un paramètre parmi votre NNI, email, site et rôle',
          severity: 'warn',
        },
      },
      permission: {
        forbidden: {
          message: "Vous n'avez pas accès à cette page",
          severity: 'err',
        },
        authenticated: {
          message: "Vous n'êtes pas connecté",
          severity: 'warn',
        },
      },
      authentification: {
        failed: {
          message: 'Authentification échouée',
          severity: 'err',
        },
        state: {
          message: 'Merci de réessayer (state missing)',
          severity: 'err',
        },
        code: {
          message: 'Merci de réessayer (code missing)',
          severity: 'err',
        },
        client_id: {
          message: 'Merci de réessayer (client_id missing)',
          severity: 'err',
        },
        nonce: {
          message: 'Merci de réessayer (nonce missing)',
          severity: 'err',
        },
        code_verifier: {
          message: 'Merci de réessayer (code_verifier)',
          severity: 'err',
        },
        sub: {
          message: 'Erreur GARDIAN (missing sub)',
          severity: 'err',
        },
      },
      websocket: {
        connected: {
          message: 'Connexion établie',
          severity: 'info',
        },
        disconnected: {
          message: 'Reconnexion en cours...',
          severity: 'err',
        },
      },
      bad_request: {
        message: 'Erreur dans la requête',
        severity: 'warn',
      },
      not_found: {
        message: 'Page introuvable',
        severity: 'warn',
      },
      health: {
        message: "Service dégradé. Ne pas utiliser l'application.",
        severity: 'err',
      },
      redis: {
        message: 'Serveur Redis injoignable',
        severity: 'err',
      },
      database: {
        message: 'Base de données injoignable',
        severity: 'err',
      },
      unknown: {
        message: 'Erreur inconnue',
        severity: 'err',
      },
      fhm: {
        message: "Requête détecté comme suspecte. Merci de transmettre le numéro de requête à votre administrateur.",
        severity: "err"
      }
    },
  };

  /**
   * Gestion d'erreur
   * @param error_code
   * @param display
   * @param extra
   * @param override
   */
  public handle_error(error_code: string, display: boolean = true, extra: string = "", override: object = {}) {
    const error_obj = this.parse_error(error_code);
    if (display && this.display) this.display_error(error_obj, extra, override);
    if (
      this.Authentification.getUser() &&
      ErrorsHandlerService.will_disconnect(error_obj)
    ) {
      this.Authentification.logout(false);
    }
    this.display = true;
  }

  /**
   * Retourne l'objet de l'erreur
   * @param error_code
   * @private
   */
  private parse_error(error_code: string): ErrorDescription {
    // Split
    let error_path: string[] = error_code.split('.');
    let error_obj = Object.assign(this.errors);

    error_path.forEach((key) => {
      if (error_obj.hasOwnProperty(key)) {
        error_obj = error_obj[key];
        error_obj.key = key;
      } else {
        error_obj = {
          message: 'Erreur inconnue',
          severity: 'err',
          key: 'unknown',
        };
      }
    });
    return error_obj;
  }

  /**
   * Affiche le toastr correspondent à l'erreur
   * @param error_obj
   * @param extra
   * @param override
   * @private
   */
  private display_error(error_obj: ErrorDescription, extra : string, override: object) {
    this.toastr.clear();
    let message = error_obj.message
    if (extra){
      message = error_obj.message +"\n"+extra
    }
    switch (error_obj.severity) {
      case 'info':
        this.toastr.info(message, 'Information', override);
        break;
      case 'warn':
        this.toastr.warning(message, 'Avertissement', override);
        break;
      default:
        this.toastr.error(message, 'Erreur', override);
    }
  }

  /**
   * Retourne si l'utilisateur doit-être déconnecté après avoir reçu cet erreur
   * @param error_obj
   * @private
   */
  private static will_disconnect(error_obj: ErrorDescription): boolean {
    let ret = false;
    if (
      error_obj.key === 'authenticated' ||
      error_obj.key == 'forbidden' ||
      error_obj.key == 'disabled'
    ) {
      ret = true;
    }

    return ret;
  }
}
