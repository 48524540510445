<!--
  ~ EDF DIPNN DT NEO SY 02/12/2021 16:46
  -->

<div class="container mt-3">

  <h1 class="title-img"><img src="/assets/img/logo_EDF_RVB.svg" alt="logo EDF" width="150rem" height="77" />Protection des données personnelles</h1>
  <div style="text-align: justify;">
    <p>
      La société EDF SA utilise vos données à caractère personnel pour <strong>pouvoir contacter rapidement les équipiers incendie en cas de problème sur le site DIPNN de la Grande Halle à Lyon</strong>.
      <br>
      Sur ce site sont hébergées plusieurs entités de la DIPNN : principalement les personnes de la Direction Technique (DT), mais aussi de la Direction Industrielle (DI), et d’autres entités de la DIPNN, voir d’EDF SA,  ainsi que des personnes prestataires en mission pour toutes ces entités de la DIPNN.
    </p>
    <p>
      Les données concernées par ce traitement sont <span style="color: #0070C0 ">votre NNI, vos noms et prénoms, votre adresse de messagerie, votre numéro de téléphone mobile et vos données de connexion (adresse IP et traces).</span>
      <br>
      Le numéro de téléphone mobile peut être professionnel, dans le cas d'une ligne fournie par l’Entreprise, ou personnel, le cas échéant. L'objectif du présent traitement est de disposer de vos coordonnées de téléphonie mobile afin de pouvoir vous avertir en cas d'évènement majeur qui aurait pour conséquence la mise en œuvre de l’évacuation du site DIPNN de La Grande Halle.

    </p>
    <span>
      Ce traitement de données à caractère personnel repose sur la base légale  :
    </span>
    <ul>
      <li>
        De l’intérêt légitime pour votre numéro de téléphone professionnel, votre adresse de messagerie et vos données de connexion
      </li>
      <li>
        Du consentement pour votre numéro de téléphone personnel
      </li>
    </ul>
    <p>
      Dans le cas de votre numéro de téléphone personnel, vous avez le droit de retirer votre consentement à tout moment. Le retrait de votre consentement ne porte pas atteinte à la licéité du traitement effectué avant ce retrait. Vous avez également la possibilité de ne pas accorder votre consentement, ce qui aura pour effet de ne pas être informé sur votre téléphone personnel dans le cas de l’évacuation du site.
    </p>
    <span>Vos données à caractère personnel sont transmises  :</span>
    <ul>
      <li>
        <span style="color: #0070C0">Au service de sécurité de LGH (notamment l’ISU), à l’astreinte de Direction EDF de la DT,  à l’équipe technique en charge de l’application InfoEvacuation</span>
      </li>
      <li>
        <span style="color: #0070C0">Au sous-traitant en charge du PC Sécurité du site</span>
      </li>
    </ul>
    <span>Elles sont conservées pendant <span style="color: #0070C0">la durée de votre mission sur le site DIPNN de La Grande Halle.</span></span>
    <br><span>Les données suivantes sont aussi supprimées :</span>
    <ul>
      <li>
        <span style="color: #0070C0">Commentaire en texte libre remonté pendant une évacuation  :</span> 72 heures après saisie
      </li>
      <li>
        <span style="color: #0070C0">Numéro de téléphone saisi manuellement :</span> jusqu’à fin de l’évacuation (=<12 heures)
      </li>
    </ul>
    <p>
      Conformément aux dispositions de la loi « Informatique et Libertés<sup>1</sup>» et du Règlement Général sur la Protection des Données<sup>2</sup> (RGPD),
      vous pouvez exercer votre droit d'accès, de rectification, votre droit à l'effacement, votre droit à la limitation du traitement
      ainsi que votre droit à la portabilité des données vous concernant <span style="color: #0070C0">en contactant par messagerie l’équipe en charge du processus d’évacuation : « <a href="mailto:dipnn-dt-infoevacuation@edf.fr?subject=[RGDP]">dipnn-dt-infoevacuation@edf.fr</a>  », avec pour information le Responsable Sécurité du SI de la DT.</span>
    </p>
    <span>Pour toute question sur le traitement de vos données, vous pouvez contacter le Délégué à la Protection des Données (DPO) désigné par EDF SA :</span>
    <ul>
      <li>Par voie électronique à l'adresse suivante : <a href="mailto:informatique-et-libertes@edf.fr">informatique-et-libertes@edf.fr</a></li>
      <li>Par voie postale à l'adresse suivante :
        <pre>
          Délégué à la Protection des Données (DPO)
          EDF - Direction des Systèmes d'Information Groupe
          Mission Informatique et Libertés
          Tour PB6, 20 place de la Défense
          92050 Paris La Défense CEDEX
        </pre>
      </li>
    </ul>
    <span style="font-size: large"><strong>Vous avez le droit de déposer une réclamation auprès de la Commission Nationale de l'Informatique et des Libertés (CNIL).</strong></span>
    <hr>
    <p>
      <sup>1</sup> Loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés
      <br>
      <sup>2</sup> Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données
    </p>
  </div>
</div>
