<!--
  ~ DIPNN DT Lyon NEO SY 06/10/2021 17:07
  ~ app.component.html - infoevacuation - f35380
  -->

<theme-header></theme-header>

<main role="main" class="wrapper">
  <ng-template [ngIf]="!Auth.locked_out && !Auth.loading && Auth.requested">
    <router-outlet></router-outlet>
  </ng-template>

  <ng-template [ngIf]="Auth.loading && !Auth.requested && !Auth.locked_out">
    <div style="width: 100%; text-align: center; margin: 45vh auto 0">
      <h1>Chargement...</h1>
      <mat-progress-bar mode="indeterminate" style="width: 75%; margin: 0 auto"></mat-progress-bar>
    </div>
  </ng-template>

  <ng-template [ngIf]="Auth.locked_out">
    <div class="container mt-5">
      <div class="text-center">
        <h1>Merci de vous connecter</h1>
        <a routerLink="/login" (click)="Auth.locked_out = false; Auth.loading = false" mat-flat-button color="primary">
          Connexion
        </a>
      </div>
    </div>
  </ng-template>
  <noscript>
    <h1 style="color: red">Merci d'activer JavaScript</h1>
  </noscript>
</main>

<theme-footer></theme-footer>
