/*
 * DIPNN DT Lyon NEO SY 06/10/2021 14:58
 * app.module.ts - infoevacuation - f35380
 */

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { AuthentificationService } from './shared/services/authentification/authentification.service';
import { IndexComponent } from './index/index.component';
import { InfoEvacuationService } from './shared/services/infoevacuation/infoevacuation.service';
import { FakeLoginComponent } from './fake-login/fake-login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RgpdComponent } from './rgpd/rgpd.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    FakeLoginComponent,
    RgpdComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFTOKEN',
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private Authentification: AuthentificationService,
    private infoevacuation: InfoEvacuationService
  ) {
    if (this.infoevacuation.isBrowser && !this.Authentification.getUser()) {
      this.Authentification.setCurrentUser();
    }
  }
}
