/*
 * EDF DIPNN DT NEO SY 06/12/2021 21:38
 */

import { Component } from '@angular/core';
import { AuthentificationService } from '../../services/authentification/authentification.service';
import { Utilisateur } from '../../interfaces/utilisateur';
import { InfoEvacuationService } from '../../services/infoevacuation/infoevacuation.service';

@Component({
  selector: 'theme-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  utilisateur?: Utilisateur;
  constructor(
    public Auth: AuthentificationService,
    public infoevacuation: InfoEvacuationService
  ) {
    if (this.infoevacuation.isBrowser) {
      this.Auth.getUserObservable().subscribe((user) => {
        this.utilisateur = user;
      });
      this.infoevacuation.get_status().subscribe(
        () => {
          this.infoevacuation.app_degradee = false;
        },
        (error) => {
          this.infoevacuation.app_degradee = true;
        }
      );
    }
  }
}
