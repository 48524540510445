/*
 * EDF DIPNN DT NEO SY 21/10/2021 15:45
 */

import { Component, OnInit } from '@angular/core';
import { InfoEvacuationService } from '../../services/infoevacuation/infoevacuation.service';
import { Version } from '../../interfaces/version';

@Component({
  selector: 'theme-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(private infoEvacuation: InfoEvacuationService) {}

  statut: Version = {
    version: 'X.X',
    environment: 'chargement',
    commit: 'chargement',
  };

  ngOnInit(): void {
    if (this.infoEvacuation.isBrowser) {
      this.infoEvacuation.get_version().subscribe((statut) => {
        this.statut = statut;
      });
    }
  }
}
