<!--
  ~ EDF DIPNN DT NEO SY 21/10/2021 16:19
  -->
<ng-template [ngIf]="!infoevacuation.fullscreen">
  <header role="banner">
    <nav role="navigation" aria-label="Menu principal" class="navigation">
      <a routerLink="/" id="logo" style="font-size: 3vh" aria-label="Logo InfoEvacuation">
        <span style="color: #003049">Info</span>
        <span style="color: #d62828;">Évacuation</span>
        <span style="color: #f77f00; vertical-align: text-top">
              <span class="material-icons" style="font-size: medium">double_arrow</span>
            </span>
      </a>
      <ul *ngIf="utilisateur" class="navigation-right list-inline">
        <li>
           <a *ngIf="utilisateur.is_staff && utilisateur.is_fully_authenticated" mat-flat-button color="primary"
           href="/admin/">Administration</a>
        </li>
        <li>
          <a title="dipnn-dt-infoevacuation@edf.fr" [href]="'mailto:dipnn-dt-infoevacuation@edf.fr?subject=[Site ' +utilisateur.site +'] '"
           mat-flat-button><i class="material-icons">email</i></a>
        </li>
        <li>
          <button mat-flat-button [title]="utilisateur.nni" (click)="Auth.logout()">
            <i class="material-icons">logout</i>
          </button>
        </li>
      </ul>
    </nav>
  </header>
</ng-template>
<ng-template [ngIf]="infoevacuation.app_degradee">
      <h3 style="color: red; text-align: center;" >Application en mode dégradé. NE PAS UTILISER.</h3>
</ng-template>
