<!--
  ~ EDF DIPNN DT NEO SY 10/02/2022 11:18
  -->

<footer role="contentinfo">
  <ul class="list-unstyled list-inline">
    <li>
      v{{ statut.version }} ({{statut.commit}})
    </li>
    <li>
      {{ statut.environment }}
    </li>
    <li>
      <a target="_blank" rel="noopener"
        href="https://edf-smi.yoomap.fr/modal/view/497023">
        Innovation@DT<i class="material-icons">launch</i></a>
    </li>
    <li>
      &copy; EDF (DIPNN DT) - 2021 &minus; 2023
    </li>
    <li>
      <a routerLink="/rgpd">Données personnelles</a>
    </li>
  </ul>
</footer>
