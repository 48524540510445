/*
 * EDF DIPNN DT NEO SY 06/12/2021 21:38
 */

import { Component } from '@angular/core';

@Component({
  selector: 'app-rgpd',
  templateUrl: './rgpd.component.html',
  styleUrls: ['./rgpd.component.scss'],
})
export class RgpdComponent {}
