/*
 * DIPNN DT Lyon NEO SY 19/08/2021 17:11
 * fake-login.component.ts - infoevacuation - f35380
 */

import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthentificationService } from '../shared/services/authentification/authentification.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Utilisateur } from '../shared/interfaces/utilisateur';
import { InfoEvacuationService } from '../shared/services/infoevacuation/infoevacuation.service';
import { Site } from '../shared/interfaces/site';

@Component({
  selector: 'app-fake-login',
  templateUrl: './fake-login.component.html',
  styleUrls: ['./fake-login.component.scss'],
})
export class FakeLoginComponent implements OnInit {
  sites?: [Site];

  auth_form: UntypedFormGroup = new UntypedFormGroup({
    nni: new UntypedFormControl(null, [
      Validators.minLength(6),
      Validators.maxLength(10),
      Validators.required,
    ]),
    nom: new UntypedFormControl(null),
    prenom: new UntypedFormControl(null),
    email: new UntypedFormControl(null, [Validators.required]),
    statut: new UntypedFormControl(null, [Validators.required]),
    site: new UntypedFormControl(null, [Validators.required]),
  });

  constructor(
    private Authentification: AuthentificationService,
    private toastr: ToastrService,
    private router: Router,
    private InfoEvacuation: InfoEvacuationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.InfoEvacuation.isBrowser) {
      this.Authentification.locked_out = false;
      this.Authentification.getUserObservable().subscribe((user) => {
        if (user && user.is_fully_authenticated) {
          this.activatedRoute.queryParams.subscribe((params) => {
            if (params.hasOwnProperty('next')) {
              this.router.navigate(params['next'].split('/'));
            } else {
              this.router.navigate(['visualisation', 'batiments']);
            }
          });
        }
      });
      this.get_sites();
    }
  }

  authentification() {
    if (!this.auth_form.valid) {
      this.toastr.error('Formulaire incomplet');
    } else {
      this.Authentification.fake_authentification(this.auth_form).subscribe(
        (reply: Utilisateur) => {
          this.Authentification.setUser(reply);
          this.toastr.info('Vous êtes connecté');
        },
        (error) => {}
      );
    }
  }

  get_sites() {
    this.InfoEvacuation.get_sites().subscribe(
      (reply: [Site]) => {
        this.sites = reply;
      },
      (error) => {
        if (error.status == 404) {
          window.location.href = '/api/oidc/login';
        }
      }
    );
  }
}
